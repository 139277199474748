<template>
  <div class="d-flex flex-column flex-root">
    <!--begin::Login-->
    <div
      id="vary_auth"
      class="login login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
    >
      <!--begin::Content-->
      <div
        class="d-flex login-content order-2 order-lg-1 flex-column position-relative p-7 overflow-hidden"
      >
        <div class="d-flex flex-column-fluid flex-center mt-20 mt-md-30 mt-lg-0">
          <router-view></router-view>
        </div>
      </div>
      <!--end::Content-->

      <!--begin::Aside-->
      <div
        class="
          login-aside
          aside-img
          order-1
          order-lg-2
          flex-row-fluid
          bgi-no-repeat
          bgi-position-y-bottom
          bgi-position-x-center
          p-10
          p-lg-15
        "
        :style="{
          backgroundImage: `url(${backgroundImage})`,
          backgroundColor: '#303440'
        }"
      >
        <!--begin: Aside Container -->
        <div class="d-flex flex-row-fluid flex-column justify-content-between">
          <!--begin: Aside header -->
          <Logo :width="150" text-color="#fff" dot-color="#1A73E8" />
          <!--end: Aside header -->
        </div>
        <!--end: Aside Container -->
      </div>
      <!--end::Aside-->
    </div>
    <!--end::Login-->
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import Logo from '@src/components/core/Logo'

  export default {
    name: 'Auth',
    components: { Logo },
    computed: {
      ...mapState({ errors: (state) => state.auth.errors }),
      backgroundImage () {
        // return `${process.env.BASE_URL }media/img/login/login_bg-1.jpg`
        return `${process.env.BASE_URL }img/mobi.png`
      }
    },
    methods: {}
  }
</script>

<!-- Load login custom page styles -->
<style lang="scss">
  @import "~@src/assets/sass/pages/auth.scss";
</style>
